
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmAlert from '@/components/shared/TmAlert.vue'
import PlanOrderSummary from '@/components/pages/plans/PlanOrderSummary.vue'
import type { StatusType } from '@/definitions/shared/types'
import SinglePageContent from '@/components/layout/SinglePageContent.vue'

export default defineComponent({
  components: {
    TmFormLine,
    TmAlert,
    PlanOrderSummary,
    SinglePageContent,
    TmButton,
  },
  setup() {
    const orderStatus = ref<StatusType>({
      color: 'orange',
      name: 'pending',
      outline: false,
    })
    const firstName = ref('')
    const lastName = ref('')

    return {
      orderStatus,
      firstName,
      lastName,
    }
  },
})
